@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 768px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 300;
  letter-spacing: 0.2em;
  color: #333;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 14px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 960px;
}

#contents_wrap {
  position: relative;
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 768px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 768px) {
  header#global_header {
    padding-top: 60px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

#nav_global {
  position: relative;
  background: url(../images/common/pt_2.png);
}

@media only screen and (max-width: 768px) {
  #nav_global {
    max-width: 100%;
  }
}

#nav_global ul {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li {
  position: relative;
  flex-grow: 1;
  border-right: 1px solid #110704;
  border-left: 1px solid #352722;
}

#nav_global ul li a {
  text-align: center;
  font-size: 16px;
  display: block;
  font-weight: 200;
  color: #fff !important;
  padding: 20px 15px;
  transition: 1s;
}

#nav_global ul li a:hover {
  opacity: 0.8;
  background: rgba(112, 62, 33, 0.4);
}

#nav_global ul li:first-child {
  border-left: 1px solid #110704;
}

#nav_global ul li:first-child a {
  border-left: 1px solid #352722;
}

#nav_global ul li:last-child {
  border-right: 1px solid #352722;
}

#nav_global ul li:last-child a {
  border-right: 1px solid #110704;
}

#nav_global ul li ul {
  position: absolute;
  width: 260px;
  top: 44px;
  right: -50px;
  margin: auto;
  display: flex;
  flex-flow: column;
}

@media only screen and (max-width: 768px) {
  #nav_global ul li ul {
    right: 0;
  }
}

#nav_global ul li ul li {
  width: 100%;
}

#nav_global ul li ul li a {
  width: 100%;
  font-size: 13px;
  color: #fff !important;
  display: block;
  padding: 15px;
}

#nav_global ul li ul li a:hover {
  transition: 0.5s;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 768px) {
  .global-menu-btn {
    text-align: center;
    z-index: 11;
    display: block;
    position: absolute;
    left: 10px;
    top: 10px;
    color: #fff;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  #hednav {
    background: url(../images/common/pt_2.png);
    justify-content: center;
    height: 60px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    margin: 0;
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #fff;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info .store1 p {
    background: url(../images/common/pt_1.png);
  }
  #hednav .wrap .navwrap #hed_info .store2 p {
    background: url(../images/common/pt_5.png);
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: solid 2px #110704;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 70px;
    line-height: 1;
    height: 100%;
    font-weight: 300;
    font-size: 0.8rem !important;
    color: #fff !important;
  }
  #hednav .wrap .navwrap #nav_global ul li a::before {
    display: block;
    position: absolute;
    left: 15px;
    top: 35%;
    z-index: 1;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    font-family: channel-icons;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 pro";
    color: #fff;
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(1) a::before {
    content: "\f80b";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(2) a::before {
    left: 17px;
    content: "\f823";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(3) a::before {
    left: 19px;
    content: "\f559";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(4) a::before {
    left: 15px;
    content: "\f0f4";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(5) a::before {
    left: 15px;
    content: "\f5a7";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(6) a::before {
    left: 14px;
    content: "\f4be";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(7) a::before {
    left: 13px;
    content: "\f5a0";
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea {
  position: relative;
}

#mainArea .tel2 {
  position: absolute;
  right: 2%;
  top: 3%;
  z-index: 11;
  background: url(../images/common/pt_1.png);
  font-size: 20px;
  color: #fff;
  padding: 20px 60px;
}

#mainArea .tel2 i {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  #mainArea .tel2 i {
    margin-right: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #mainArea .tel2 {
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 12;
    padding: 10px 15px;
  }
  #mainArea .tel2 span {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .tel2 {
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 12;
    padding: 0;
  }
  #mainArea .tel2 a {
    display: block;
    padding: 16px 18px;
    color: #fff;
  }
  #mainArea .tel2 a span {
    display: none;
  }
}

#mainArea #siteID2 {
  position: absolute;
  z-index: 10;
  left: 2%;
  top: 3%;
}

@media only screen and (max-width: 768px) {
  #mainArea #siteID2 {
    position: fixed;
    top: 5px;
    left: 10%;
    z-index: 12;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea #siteID2 {
    left: 35%;
    top: 10px;
    width: 25%;
  }
}

#mainArea #siteID2 img {
  width: 100% !important;
  max-width: calc( 1238px / 5);
}

@media only screen and (max-width: 1000px) {
  #mainArea #siteID2 img {
    width: 70% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #mainArea #siteID2 img {
    width: 45% !important;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea #siteID2 img {
    width: 100% !important;
  }
}

#mainArea #siteID {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

#mainArea #siteID h1 {
  display: table;
  height: 100%;
  width: 100%;
}

#mainArea #siteID h1 span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

#mainArea #siteID h1 span a {
  display: inline-block;
  opacity: 1 !important;
}

#mainArea #siteID h1 span img {
  width: 100% !important;
  max-width: calc( 1238px / 2);
}

@media only screen and (max-width: 1000px) {
  #mainArea #siteID h1 span img {
    width: 70% !important;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea #siteID h1 span img {
    width: 50% !important;
  }
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/main2.jpg);
  background-position: center;
}

@media only screen and (max-width: 768px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 4em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

@media only screen and (max-width: 768px) {
  #local-keyvisual h1 {
    font-size: 2em;
  }
}

#local-keyvisual h1 span {
  padding: 300px 0;
  display: block;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #local-keyvisual h1 span {
    padding: 150px 0;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 span {
    padding: 80px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 768px) {
  .pan1 {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .pan1 {
    margin: 20px 10px;
  }
}

.pan1 a {
  color: #000;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  font-size: 13px;
  color: #000;
  margin-top: 100px;
}

@media only screen and (max-width: 768px) {
  footer#global_footer {
    margin-top: 50px;
  }
}

footer#global_footer a {
  color: #333;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer {
  position: relative;
  background: #333;
  padding: 50px 0;
  z-index: -2;
}

@media only screen and (max-width: 1200px) {
  #footer {
    padding: 50px 10px;
  }
}

@media only screen and (max-width: 768px) {
  #footer {
    z-index: 0;
  }
}

#footer:after {
  z-index: -1;
  content: "";
  background: url(../images/common/img_5.png) no-repeat right bottom;
  position: absolute;
  right: 50px;
  bottom: -50px;
  display: block;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1200px) {
  #footer:after {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  #footer .inner2 {
    display: block;
  }
}

#footer .inner2 .footer_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  #footer .inner2 .footer_info {
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner2 .footer_info {
    display: block;
  }
}

#footer .inner2 .footer_info > div {
  padding: 5%;
  width: 350px;
  height: 350px;
  margin: 0 -15px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.13);
}

@media only screen and (max-width: 1000px) {
  #footer .inner2 .footer_info > div {
    margin: 0;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner2 .footer_info > div {
    margin: 0;
    width: auto;
    height: auto;
    box-shadow: inherit;
  }
}

#footer .inner2 .footer_info .circle1 {
  background: #000;
  border-radius: 50% 50% 50% 70%/50% 50% 70% 60%;
}

@media only screen and (max-width: 640px) {
  #footer .inner2 .footer_info .circle1 {
    border-radius: 0;
    margin-bottom: 50px;
  }
}

#footer .inner2 .footer_info .circle2 {
  background: #000;
  border-radius: 80% 30% 50% 50%/50%;
}

@media only screen and (max-width: 640px) {
  #footer .inner2 .footer_info .circle2 {
    border-radius: 0;
  }
  #footer .inner2 .footer_info .circle2 a {
    color: #fff;
  }
}

#footer .inner2 .footer_info .tel2 {
  font-size: 20px;
  text-align: center;
}

#footer .inner2 .footer_info dl {
  color: #fff;
}

#footer .inner2 .footer_info dl dt {
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
}

#footer .inner2 .footer_info dl dd {
  font-size: 14px;
}

#footer p {
  text-align: center;
  color: #fff;
  margin-top: 30px;
}

#copy {
  position: relative;
  z-index: 10;
  text-align: center;
  font-size: 13px;
  display: block;
  padding: 10px 0;
}

#copy a {
  color: #000;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

.cont_1 {
  line-height: 1.7em;
  padding: 100px 10px;
  background-image: url(../images/common/cont_1bg.png), url(../images/common/pt_3.png);
  background-repeat: no-repeat,repeat;
  background-position: right top,left top;
}

@media only screen and (max-width: 640px) {
  .cont_1 {
    display: block;
    padding: 50px 10px;
  }
}

.cont_2 {
  line-height: 1.7em;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .cont_2 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.cont_2 .flxL {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../images/common/cont_2bg.png), url(../images/common/pt_4.png);
  background-repeat: no-repeat,repeat;
  background-position: left top,left top;
  width: 45%;
}

@media only screen and (max-width: 1200px) {
  .cont_2 .flxL {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_2 .flxL {
    width: 100%;
    margin-bottom: 0;
    padding: 80px 10px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2 .flxL {
    padding: 50px 10px;
  }
}

.cont_2 .flxL .flxLwrap {
  width: 100%;
}

.cont_2 .flxR {
  width: 55%;
}

@media only screen and (max-width: 768px) {
  .cont_2 .flxR {
    width: 100%;
  }
  .cont_2 .flxR img {
    width: 100% !important;
  }
}

.btn {
  margin: 0 auto;
  width: 40%;
}

@media only screen and (max-width: 1200px) {
  .btn {
    padding: 0 10px;
    width: 60%;
  }
}

@media only screen and (max-width: 1000px) {
  .btn {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .btn {
    display: block;
    padding: 0;
    width: 100%;
  }
}

.btn a {
  position: relative;
  display: block;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  transition: 1s;
}

.btn a:hover {
  opacity: 0.7;
  color: #fff;
}

.btn a span {
  padding: 20px;
  display: block;
  background: url(../images/common/pt_1.png);
  position: relative;
  z-index: 1;
}

.btn a:after {
  content: "";
  background: url(../images/common/pt_5.png);
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.cont_3 {
  background: url(../images/common/cont_3bg.png) no-repeat right top;
  line-height: 1.7em;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 1000px) {
  .cont_3 {
    padding: 100px 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .cont_3 {
    padding: 50px 10px !important;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 {
    display: block;
    padding: 50px 0 0 0 !important;
  }
}

.cont_3 .inner2 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .cont_3 .inner2 {
    display: block;
    padding: 0 10px;
  }
}

.cont_3 .inner2 .box {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_3 .inner2 .box {
    width: 100%;
    margin-bottom: 50px;
  }
}

.cont_3 .inner2 .box article {
  height: 100%;
}

.cont_3 .inner2 .box article table {
  height: 100%;
}

.cont_3 .inner2 .box article table td {
  padding: 0 !important;
  border: 0 !important;
}

.cont_3 .inner2 .box article table img {
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  .cont_3 .inner2 .box article table img {
    margin-bottom: 20px;
  }
}

.cont_3 .inner2 .box article table h2 {
  margin-bottom: 30px;
  color: #333;
  letter-spacing: 0.2em;
  font-weight: 400;
  line-height: 1.5em;
  font-size: 23px;
  font-size: 1.4375vw;
}

@media only screen and (min-width: 1600px) {
  .cont_3 .inner2 .box article table h2 {
    font-size: 23px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .cont_3 .inner2 .box article table h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .inner2 .box article table h2 {
    line-height: 1.7em;
    font-size: 18px;
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
  }
}

.cont_3 .inner2 .box article .btn > div {
  margin-top: 30px;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .inner2 .box article .btn > div {
    width: 50% !important;
    margin: 30px auto 0;
  }
}

@media only screen and (max-width: 768px) {
  .cont_3 .inner2 .box article .btn > div {
    width: 100% !important;
  }
}

.bnr {
  max-width: 960px;
  margin: 0 auto;
  justify-content: space-between;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .bnr {
    padding: 0 10px;
  }
}

.bnr .box {
  width: 48%;
  background: url(../images/common/pt_2.png);
}

@media only screen and (max-width: 768px) {
  .bnr .box {
    width: 100%;
    margin-top: 30px;
  }
}

.bnr .box a {
  display: block;
  color: #fff;
  padding: 50px 10px;
  font-size: 23px;
  font-size: 1.4375vw;
}

@media only screen and (min-width: 1600px) {
  .bnr .box a {
    font-size: 23px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .bnr .box a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .bnr .box a {
    line-height: 1.7em;
    font-size: 16px;
  }
}

.bnr .box a:hover {
  opacity: 0.8;
  background: rgba(112, 62, 33, 0.4);
}

.cont_4 {
  line-height: 1.7em;
}

@media only screen and (max-width: 1000px) {
  .cont_4 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_4 {
    margin: 50px 0;
  }
}

.cont_4 .inner2 {
  padding: 30px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 768px) {
  .cont_4 .inner2 {
    display: block;
  }
}

.cont_4 .flxL {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
}

@media only screen and (max-width: 768px) {
  .cont_4 .flxL {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_4 .flxL {
    padding: 0;
  }
}

.cont_4 .flxL .flxLwrap {
  width: 100%;
}

.cont_4 .flxR {
  width: 65%;
}

@media only screen and (max-width: 768px) {
  .cont_4 .flxR {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .cont_4 h2 {
    text-align: center;
  }
}

.noimg {
  display: none;
}

.news .newswrap {
  height: 350px;
  overflow-y: scroll;
  position: relative;
  border: 0;
}

.news .newswrap dl {
  display: block;
  margin-bottom: 20px;
  padding: 0 20px 0 0;
}

@media only screen and (max-width: 768px) {
  .news .newswrap dl {
    flex-direction: row;
  }
}

.news .newswrap dl dt {
  border: 0 !important;
  width: 100% !important;
  background: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #00a2ff;
}

.news .newswrap dl dt:last-of-type dt {
  border-bottom: 0;
}

.news .newswrap dl dd {
  border: 0;
  width: 100% !important;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ccc;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .news .newswrap dl dd {
    width: 75% !important;
  }
}

@media only screen and (max-width: 640px) {
  .news .newswrap dl dd {
    width: 100% !important;
  }
}

/* title */
.midashi {
  display: block;
  width: 100%;
}

.midashi h2 {
  color: #333;
  letter-spacing: 0.2em;
  font-weight: 400;
  line-height: 1.5em;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .midashi h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    line-height: 1.7em;
    font-size: 23px;
  }
}

.midashi h2 span {
  display: block;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 span {
    font-size: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .midashi h2 span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h2 span {
    line-height: 1.7em;
    font-size: 16px;
  }
}

.midashi2 h2 {
  padding: 15px 10px;
  background: #f4f4f4;
  border-left: solid 5px #645B8F;
  border-bottom: solid 3px #d7d7d7;
  line-height: 1.7em;
  color: #333;
  font-weight: 400;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 h2 {
    font-size: 20px;
  }
}

.midashi3 {
  display: block;
  width: 100%;
}

.midashi3 h2 {
  color: #333;
  letter-spacing: 0.2em;
  font-weight: 400;
  line-height: 1.5em;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h2 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 h2 {
    line-height: 1.7em;
    font-size: 18px;
  }
}

.bg_100per_wrap {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 1200px) {
  .bg_100per_wrap {
    padding: 100px 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  background-image: url(../images/common/cont_1bg.png), url(../images/common/pt_3.png);
  background-repeat: no-repeat,repeat;
  background-position: right top,left top;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background-size: contain;
  z-index: -1;
}

.bg_100per_wrap2 {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 1200px) {
  .bg_100per_wrap2 {
    padding: 100px 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .bg_100per_wrap2 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  background-image: url(../images/common/cont_2bg.png), url(../images/common/pt_4.png);
  background-repeat: no-repeat,repeat;
  background-position: left top,left top;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background-size: contain;
  z-index: -1;
}

.bor0 td {
  border: 0;
}

.noodles1 {
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .noodles1 {
    flex-direction: row;
  }
}

.noodles1 .box {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .noodles1 .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.noodles1 .box img {
  width: 100% !important;
  margin-bottom: 20px;
}

.noodles1 .box h3 {
  font-size: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
}

@media only screen and (max-width: 640px) {
  .noodles1 .box h3 {
    font-size: 18px;
  }
}

.cont_5 {
  border: 1px solid #ccc;
  padding: 50px;
  background: url(../images/common/pt_3.png);
  margin-bottom: 100px;
}

@media only screen and (max-width: 1200px) {
  .cont_5 {
    margin: 0 10px 100px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_5 {
    margin: 0;
    margin: 0 0 50px;
    padding: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_5 {
    padding: 10px;
  }
}

.cont_6 {
  position: relative;
  padding: 50px;
  border: 6px double #645B8F;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1200px) {
  .cont_6 {
    margin: 0 10px 100px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_6 {
    margin: 0 0 50px;
    padding: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_6 {
    padding: 10px;
    display: block !important;
  }
}

.cont_6:before {
  content: "";
  background: url(../images/common/pt_1.png);
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  z-index: -1;
}

.cont_6 .flxL {
  width: 68%;
}

@media only screen and (max-width: 640px) {
  .cont_6 .flxL {
    width: 100%;
  }
}

.cont_6 .flxR {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .cont_6 .flxR {
    width: 100%;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

.comp dl {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.comp dl:nth-child(1) {
  border-top: 1px solid #ccc;
}

.comp dl dt {
  border: 0 !important;
  background-color: inherit !important;
}

@media only screen and (max-width: 640px) {
  .comp dl dt {
    width: 70% !important;
    padding: 0 10px 0 0 !important;
  }
}

.comp dl dd {
  border: 0 !important;
  background-color: inherit !important;
}

@media only screen and (max-width: 640px) {
  .comp dl dd {
    width: 30% !important;
    text-align: right !important;
    padding: 0 0 0 10px !important;
  }
}

.comp dl a {
  color: #b2b2b2;
}

@media only screen and (max-width: 640px) {
  .comp2 dl {
    display: block !important;
  }
}

.comp2 dl dt {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dt {
    width: 100% !important;
  }
}

.comp2 dl dd {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dd {
    width: 100% !important;
  }
}

.comp2 dl a {
  color: #b2b2b2;
}

.faq_q {
  border: none !important;
}

.faq_q > div {
  font-size: 16px;
  border-bottom: 1px #8cafc0 dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #8cafc0;
  font-weight: bold;
  position: relative;
  padding-left: 3em !important;
}

.faq_q > div:before {
  content: "Q";
  display: block;
  background: #8cafc0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -9px;
}

.faq_a {
  border: none !important;
}

.faq_a > div {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #ccc solid;
  padding-bottom: 30px !important;
  position: relative;
  padding-left: 3em !important;
}

.faq_a > div:before {
  content: "A";
  display: block;
  background: #9fcfe6;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
}

@media only screen and (max-width: 640px) {
  .img100 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .img100 .box {
    margin-bottom: 20px;
  }
}
